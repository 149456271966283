import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import { i18n, loadLanguageAsync } from "@/i18n";

import { DataClient } from "@/services/DataClient";
import { Component } from "vue";
import { Utilities } from "@/services/Utilities";

Vue.use(VueRouter);

// const supportedLocales = ["es", "en", "it", "fr", "de"];
// const defaultLocale = "en";

// function getFilteredUserLocale() {
//     const userLocale = navigator.language || (navigator as any).userLanguage || defaultLocale;
//     const userLocaleShort = userLocale.split("-")[0];

//     return supportedLocales.includes(userLocaleShort) ? userLocaleShort : defaultLocale;
// }

interface TranslatableRoute {
    name: string;
    paths: {
        [key: string]: string;
    };
    component: Component | (() => Promise<any>);
    meta?: any;
}

const translatableRoutes: Array<TranslatableRoute> = [
    {
        name: "home",
        paths: {
            es: "/inicio",
            en: "/home",
            fr: "/accueil",
            de: "/startseite",
            it: "/inizio",
            pt: "/pagina-inicial",
            pl: "/strona-glowna",
            cs: "/domov",
        },
        component: () => import(/* webpackChunkName: "home" */ "../views/HomePageView.vue"),
    },
    {
        name: "activities",
        paths: {
            es: "/actividades",
            en: "/activities",
            fr: "/activites",
            de: "/aktivitaten",
            it: "/attivitas",
            pt: "/atividades",
            pl: "/aktywnosci",
            cs: "/aktivity",
        },
        component: () => import(/* webpackChunkName: "activities" */ "../views/ActivitiesPageView.vue")
    },
    {
        name: "activity",
        paths: {
            es: "/actividad/:id",
            en: "/activity/:id",
            fr: "/activite/:id",
            de: "/aktivitat/:id",
            it: "/attivita/:id",
            pt: "/atividade/:id",
            pl: "/aktywnosc/:id",
            cs: "/aktivita/:id",
        },
        // meta: {
        //     divider: true,
        // },
        component: () => import(/* webpackChunkName: "activities" */ "../views/ActivityPageView.vue"),
    },
    {
        name: "category",
        paths: {
            es: "/actividades/:id",
            en: "/activities/:id",
            fr: "/activites/:id",
            de: "/aktivitaten/:id",
            it: "/attivitas/:id",
            pt: "/atividades/:id",
            pl: "/aktywnosci/:id",
            cs: "/aktivity/:id",
        },
        meta: {
            divider: false,
        },
        component: () => import(/* webpackChunkName: "activities" */ "../views/CategoryPageView.vue")
    },
    {
        name: "blog",
        paths: {
            es: "/blog",
            en: "/blog",
            fr: "/blog",
            de: "/blog",
            it: "/blog",
            pt: "/blog",
            pl: "/blog",
            cs: "/blog",
        },
        meta: {
            divider: false,
        },
        component: () => import(/* webpackChunkName: "blog" */ "../views/BlogPageView.vue")
    },
    {
        name: "post",
        paths: {
            es: "/blog/:year/:month/:day/:slug",
            en: "/blog/:year/:month/:day/:slug",
            fr: "/blog/:year/:month/:day/:slug",
            de: "/blog/:year/:month/:day/:slug",
            it: "/blog/:year/:month/:day/:slug",
            pt: "/blog/:year/:month/:day/:slug",
            pl: "/blog/:year/:month/:day/:slug",
            cs: "/blog/:year/:month/:day/:slug",
        },
        component: () => import(/* webpackChunkName: "blog" */ "../views/PostPageView.vue")
    },
    {
        name: "my-booking",
        paths: {
            es: "/mi-reserva",
            en: "/my-booking",
            fr: "/ma-reservation",
            de: "/meine-buchung",
            it: "/il-mio-prenotazione",
            pt: "/minha-reserva",
            pl: "/moja-rezerwacja",
            cs: "/moje-rezervace",
        },
        component: () => import(/* webpackChunkName: "cart" */ "../views/MyBookingPageView.vue")
    },
    {
        name: "cart",
        paths: {
            es: "/comprar/:token",
            en: "/purchase/:token",
            fr: "/acheter/:token",
            de: "/kaufen/:token",
            it: "/comprare/:token",
            pt: "/comprar/:token",
            pl: "/kupic/:token",
            cs: "/nakup/:token",
        },
        meta: {
            divider: true,
        },
        component: () => import(/* webpackChunkName: "cart" */ "../views/CartPageView.vue")
    },
    {
        name: "about",
        paths: {
            es: "/sobre-nosotros",
            en: "/about-us",
            fr: "/a-propos-de-nous",
            de: "/uber-uns",
            it: "/su-di-noi",
            pt: "/sobre-nos",
            pl: "/o-nas",
            cs: "/o-nas",
        },
        component: () => import(/* webpackChunkName: "extra-pages" */ "../views/AboutPageView.vue")
    },
    {
        name: "contact",
        paths: {
            es: "/contacto",
            en: "/contact-us",
            fr: "/contactez-nous",
            de: "/kontaktieren-sie-uns",
            it: "/contattaci",
            pt: "/contato",
            pl: "/kontakt",
            cs: "/kontakt",
        },
        component: () => import(/* webpackChunkName: "extra-pages" */ "../views/ContactPageView.vue")
    },
    {
        name: "privacy-policy",
        paths: {
            es: "/politica-de-privacidad",
            en: "/privacy-policy",
            fr: "/politique-de-confidentialite",
            de: "/datenschutzrichtlinie",
            it: "/politica-di-privacy",
            pt: "/politica-de-privacidade",
            pl: "/polityka-prywatnosci",
            cs: "/ochrana-osobnich-udaju",
        },
        component: () => import(/* webpackChunkName: "extra-pages" */ "../views/PrivacyPolicyPageView.vue")
    },
    {
        name: "use-conditions",
        paths: {
            es: "/condiciones-de-uso",
            en: "/use-conditions",
            fr: "/conditions-dutilisation",
            de: "/nutzungsbedingungen",
            it: "/condizioni-di-utilizzo",
            pt: "/condicoes-de-uso",
            pl: "/warunki-korzystania",
            cs: "/podminky-pouzivani",
        },
        component: () => import(/* webpackChunkName: "extra-pages" */ "../views/UseConditionsPageView.vue")
    },
    {
        name: "general-conditions",
        paths: {
            es: "/condiciones-generales",
            en: "/general-conditions",
            fr: "/conditions-generales",
            de: "/allgemeine-bedingungen",
            it: "/condizioni-generali",
            pt: "/condicoes-gerais",
            pl: "/ogolne-warunki",
            cs: "/vseobecne-podminky",
        },
        component: () => import(/* webpackChunkName: "extra-pages" */ "../views/GeneralConditionsPageView.vue")
    },
    {
        name: "meeting-points",
        paths: {
            es: "/puntos-de-recogida",
            en: "/meeting-points",
            fr: "/points-de-rencontre",
            de: "/treffpunkte",
            it: "/punti-di-incontro",
            pt: "/pontos-de-encontro",
            pl: "/punkty-spotkan",
            cs: "/setkavaci-mista",
        },
        component: () => import(/* webpackChunkName: "extra-pages" */ "../views/MeetingPointListPageView.vue")
    },
    {
        name: "faqs",
        paths: {
            es: "/faqs",
            en: "/faqs",
            fr: "/faqs",
            de: "/faqs",
            it: "/faqs",
            pt: "/faqs",
            pl: "/faqs",
            cs: "/faqs",
        },
        component: () => import(/* webpackChunkName: "extra-pages" */ "../views/FaqsPageView.vue")
    },
    {
        name: "upsell",
        paths: {
            es: "/mejora-mi-experiencia",
            en: "/upgrade-my-experience",
            fr: "/ameliorer-mon-experience",
            de: "/verbessern-sie-meine-erfahrung",
            it: "/migliora-la-mia-esperienza",
            pt: "/melhorar-minha-experiencia",
            pl: "/ulepsz-moje-doswiadczenie",
            cs: "/vylepsit-mou-zkusenost",
        },
        component: () => import(/* webpackChunkName: "upsell" */ "../views/UpsellPageView.vue")
    },
    {
        name: "service",
        paths: {
            es: "/service/:id",
            en: "/service/:id",
            fr: "/service/:id",
            de: "/service/:id",
            it: "/service/:id",
            pt: "/service/:id",
            pl: "/service/:id",
            cs: "/service/:id",
        },
        component: () => import(/* webpackChunkName: "service" */ "../views/ServicePageView.vue")
    },
];

export function initializeRouter(): { router: VueRouter, initialLocale: string } {
    const supportedLocales = DataClient.instance.supportedLocales;
    const defaultLocale = DataClient.instance.defaultLocale;

    const getFinalLocale = (locale?: string) => {
        const targetLocale = locale || Utilities.getBrowserLanguage();
        return supportedLocales.includes(targetLocale) ? targetLocale : defaultLocale;
    };

    const routes: Array<RouteConfig> = [
        {
            path: "/",
            redirect: () => {
                return getFinalLocale();
            }
        }, {
            name: "redirector",
            path: "/r",
            component: () => import(/* webpackChunkName: "home" */ "../components/Redirector.vue"),
        }
    ];

    supportedLocales.forEach((locale) => {
        routes.push({
            path: `/${locale}`,
            redirect: { name: `home.${locale}` }
        });
    });

    translatableRoutes.forEach((route) => {
        supportedLocales.forEach((locale) => {
            const path = route.paths[locale];

            if (path) {
                routes.push({
                    name: `${route.name}.${locale}`,
                    path: `/${locale}${path}`,
                    component: route.component,
                    meta: route.meta
                });
            }
        });
    });

    routes.push(
        {
            name: "not-found",
            path: "*",
            beforeEnter: (to, from, next) => {
                window.location.href = `/404`;
            }
        }
    );

    const router = new VueRouter({
        mode: "history",
        base: process.env.BASE_URL,
        routes,
        scrollBehavior: (to, from, savedPosition) => {
            if (savedPosition) {
                return savedPosition;
            } else {
                return { x: 0, y: 0 };
            }
        }
    });

    router.beforeEach(async (to, from, next) => {
        if (to.name === "redirector") {
            next();
            return;
        }

        const targetLocale = ((to.path || "").split("/")[1] || "").toLowerCase();
        const locale = supportedLocales.includes(targetLocale) ? targetLocale : defaultLocale;

        if (locale !== i18n.locale) {
            await loadLanguageAsync(locale);
        }

        await DataClient.instance.loadConfiguration(locale);

        next();
    });

    const targetLocale = window.location.pathname.split("/")[1] || Utilities.getBrowserLanguage();
    const initialLocale = supportedLocales.includes(targetLocale) ? targetLocale : defaultLocale;

    return { router, initialLocale };
}
