import { i18n } from "@/i18n";
import { BaseService } from "@/services/BaseService";
import { ConfirmWebApiBookingRequest, ConfirmWebApiBookingResponse, DecodeWebApiPurchaseTokenResponse, DecodeWebApiPurchaseTokenV2Response, GenerateWebApiPurchaseTokenRequest, GenerateWebApiPurchaseTokenResponse, GenerateWebApiPurchaseTokenV2Request, GenerateWebApiPurchaseTokenV2Response, GetAllWebApiPostsResponse, GetWebApiActivityAvailabilityRequest, GetWebApiActivityAvailabilityResponse, GetWebApiAutocompletionDetailsResponse, GetWebApiAutocompletionResponse, GetWebApiBookingAccessTokenRequest, GetWebApiBookingAccessTokenResponse, GetWebApiBookingDetailsRequest, GetWebApiBookingDetailsResponse, GetWebApiConfigurationRequest, GetWebApiConfigurationResponse, GetWebApiGlobalConfigurationResponse, GetWebApiMeetingPointsRequest, GetWebApiMeetingPointsResponse, GetWebApiPostResponse, GetWebApiProductAvailabilityRequest, GetWebApiProductAvailabilityResponse, GetWebApiProductPricingRequest, GetWebApiProductPricingResponse, GetWebApiServiceInformationRequest, GetWebApiServiceInformationResponse, GetWebApiUpsellInformationRequest, GetWebApiUpsellInformationResponse, GetWebAvailabilityRequest, GetWebAvailabilityResponse, PreconfirmWebApiBookingRequest, PreconfirmWebApiBookingResponse, VerifyWebApiPurchaseTokenRequest, VerifyWebApiPurchaseTokenResponse, WebApiActivity, WebApiCategory } from "@/services/ClientInterfaces";


export interface Activity extends WebApiActivity {
    category: string;
}

export type Category = WebApiCategory;

export interface Occupancy {
    adults: number;
    children: number;
    babies: number;
    paxes?: number;
    drivers?: number;
}

export interface Pricing {
    adultPrice: number;
    childPrice: number;
    babyPrice: number;
    paxPrices: number[];
    driverPrice: number;
}

export class DataClient extends BaseService {

    public static readonly instance = new DataClient();

    private static readonly defaultConfiguration: GetWebApiConfigurationResponse = {
        website: {
            id: "",
            name: "",
            host: "",
        },
        headerType: "",
        fullScreenCarrousel: false,
        categories: [],
        activities: [],
        filters: [],
        zones: [],
        section1: {
            name: "",
            description: "",
            items: []
        },
        section2: {
            name: "",
            description: "",
            items: []
        },
        section3: {
            name: "",
            description: "",
            items: []
        },
        section4: {
            name: "",
            description: "",
            text1: "",
            text2: "",
            text3: "",
            text4: "",
            text5: "",
            text6: "",
            text7: "",
            text8: "",
            text9: ""
        },
        section5: {
            name: "",
            description: "",
            items: []
        },
        latestPosts: [],
    };

    public static readonly defaultCategory: Category = {
        id: "",
        name: "unknown",
        slug: "unknown",
        description: "unknown",
        images: ["https://via.placeholder.com/512.png"]
    };

    public static readonly defaultActivity: Activity = {
        id: "",
        name: "unknown",
        slug: "unknown",
        description: "unknown",
        shortDescription: "unknown",
        transferDescription: "unknown",
        category: "",
        categories: [],
        images: [],
        tags: [],
        highlights: [],
        inclusions: [],
        exclusions: [],
        unsuitness: [],
        itinerary: [],
        documents: [],
        extendedDocuments: [],
        duration: 0,
        rating: 0,
        standardPrice: 0,
        directPrice: 0,
        bestPrice: 0,
        direct: false,
        canBePurchasedDirect: false,
        hasUnitProducts: false,
        exclusive: false
    };

    protected loadedConfigurations: { [locale: string]: GetWebApiConfigurationResponse } = {};
    protected loadedGlobalConfiguration: GetWebApiGlobalConfigurationResponse | null = null;

    public get primaryColor(): string {
        // caledonia
        return "#052e5a";

        // funisland
        // return "#EF6C00";
    }

    public get secondaryColor(): string {
        return "red darken-4";
    }

    public get supportedLocales(): string[] {
        return (this.globalConfiguration.languages || []).map(l => l.code);
    }

    public get defaultLocale(): string {
        return (this.globalConfiguration.languages || []).find(l => l.default)?.code || "en";
    }

    public get globalConfiguration(): GetWebApiGlobalConfigurationResponse {
        return this.loadedGlobalConfiguration || {} as GetWebApiGlobalConfigurationResponse;
    }

    public get configuration(): GetWebApiConfigurationResponse {
        const locale = i18n.locale;

        // DEBUG LANGUAGE LOADING
        /*
        const loadedConfiguration = this.loadedConfigurations[locale];
        if (loadedConfiguration) {
            console.log("Get configuration OK", locale)
            return loadedConfiguration;
        } else {
            console.log("Get configuration KO", locale)
            return DataClient.defaultConfiguration;
        }
        */

        return this.loadedConfigurations[locale] || DataClient.defaultConfiguration;
    }

    public async getConfiguration(locale: string): Promise<GetWebApiConfigurationResponse> {
        return this.httpGet<GetWebApiConfigurationResponse>(["configuration", locale]);
    }

    public async autocompleteAddress(query: string): Promise<GetWebApiAutocompletionResponse> {
        return this.httpGet<GetWebApiAutocompletionResponse>(["address", "autocomplete"], { query });
    }

    public async getAutocompletionDetails(id: string, resolvePickupLocation?: boolean): Promise<GetWebApiAutocompletionDetailsResponse> {
        return this.httpPost<GetWebApiAutocompletionDetailsResponse>(["address", "autocomplete"], { id: id, resolveZone: resolvePickupLocation });
    }

    public async loadGlobalConfiguration(): Promise<void> {
        if (!this.loadedGlobalConfiguration) {
            this.loadedGlobalConfiguration = await this.httpGet<GetWebApiGlobalConfigurationResponse>(["configuration"]);
        }
    }

    public async loadConfiguration(locale: string): Promise<void> {
        if (!this.loadedConfigurations[locale]) {
            const newConfiguration = await this.getConfiguration(locale);

            for (const activity of newConfiguration.activities) {
                (activity as Activity).category = newConfiguration.categories.find(c => c.id === activity.categories[0])?.name || "";
            }

            this.loadedConfigurations[locale] = newConfiguration;
        }
    }

    public getActivityById(id: string): Activity {
        return (this.configuration.activities.find(a => a.id === id) || DataClient.defaultActivity) as Activity;
    }

    public getActivityBySlug(id: string): Activity {
        return (this.configuration.activities.find(a => a.slug === id) || DataClient.defaultActivity) as Activity;
    }

    public getAllActivities(): Activity[] {
        return this.configuration.activities as Activity[];
    }

    public getCategoryById(id: string): Category {
        return this.configuration.categories.find(c => c.id === id) || DataClient.defaultCategory;
    }

    public getCategoryBySlug(id: string): Category {
        return this.configuration.categories.find(c => c.slug === id) || DataClient.defaultCategory;
    }

    public getAvailability(request: GetWebAvailabilityRequest): Promise<GetWebAvailabilityResponse> {
        return this.httpPost<GetWebAvailabilityResponse>(["availability"], request);
    }

    public async getActivityAvailability(request: GetWebApiActivityAvailabilityRequest): Promise<GetWebApiActivityAvailabilityResponse> {
        return this.httpPost<GetWebApiActivityAvailabilityResponse>(["activity-availability"], request);
    }

    public async getProductAvailability(request: GetWebApiProductAvailabilityRequest): Promise<GetWebApiProductAvailabilityResponse> {
        return this.httpPost<GetWebApiProductAvailabilityResponse>(["product-availability"], request);
    }

    public async getProductPricing(request: GetWebApiProductPricingRequest): Promise<GetWebApiProductPricingResponse> {
        return this.httpPost<GetWebApiProductPricingResponse>(["product-pricing"], request);
    }

    public async generatePurchaseTokenV2(request: GenerateWebApiPurchaseTokenV2Request): Promise<GenerateWebApiPurchaseTokenV2Response> {
        return this.httpPost<GenerateWebApiPurchaseTokenV2Response>(["purchase-token", "generate-v2"], request);
    }

    public async decodePurchaseTokenV2(token: string, locale: string): Promise<DecodeWebApiPurchaseTokenV2Response> {
        return this.httpPost<DecodeWebApiPurchaseTokenV2Response>(["purchase-token", "decode-v2"], { token, locale });
    }

    public async preconfirmBooking(request: PreconfirmWebApiBookingRequest): Promise<PreconfirmWebApiBookingResponse> {
        return this.httpPost<PreconfirmWebApiBookingResponse>(["booking", "preconfirm"], request);
    }

    public async confirmBooking(request: ConfirmWebApiBookingRequest): Promise<ConfirmWebApiBookingResponse> {
        return this.httpPost<ConfirmWebApiBookingResponse>(["booking", "confirm"], request);
    }

    public async getBookingAccessToken(request: GetWebApiBookingAccessTokenRequest): Promise<GetWebApiBookingAccessTokenResponse> {
        return this.httpPost<GetWebApiBookingAccessTokenResponse>(["booking", "access-token"], request);
    }

    public async getBookingDetails(request: GetWebApiBookingDetailsRequest): Promise<GetWebApiBookingDetailsResponse> {
        return this.httpPost<GetWebApiBookingDetailsResponse>(["booking", "details"], request);
    }

    public async getMeetingPoints(request: GetWebApiMeetingPointsRequest): Promise<GetWebApiMeetingPointsResponse> {
        return this.httpPost<GetWebApiMeetingPointsResponse>(["meeting-points"], request);
    }

    public async getAllPosts(): Promise<GetAllWebApiPostsResponse> {
        const websiteId = this.configuration.website.id;
        const locale = i18n.locale;
        return this.httpGet<GetAllWebApiPostsResponse>([websiteId, locale, "posts"]);
    }

    public async getPost(slug: string): Promise<GetWebApiPostResponse> {
        const websiteId = this.configuration.website.id;
        const locale = i18n.locale;
        return this.httpGet<GetWebApiPostResponse>([websiteId, locale, "posts", slug]);
    }

    public async getUpsellInformation(request: GetWebApiUpsellInformationRequest): Promise<GetWebApiUpsellInformationResponse> {
        return this.httpPost<GetWebApiUpsellInformationResponse>(["upsell-information"], request);
    }

    public async getServiceInformation(request: GetWebApiServiceInformationRequest): Promise<GetWebApiServiceInformationResponse> {
        return this.httpPost<GetWebApiServiceInformationResponse>(["service-information"], request);
    }

    public getInsurancePricePerDriver(productId: string): number {
        const safariProducts = [
            "3ae9ebb8-4ee7-45a1-9053-c9159732e083",
            "f1295f36-ef46-4b89-baed-60efaa210947",
            "102fca23-9a7c-4659-95f1-692afac0fa25"
        ];

        if (safariProducts.includes(productId)) {
            return 29.0;
        } else {
            return 0;
        }
    }

    public getJeepText(productId: string): string {
        const safariProducts = [
            "3ae9ebb8-4ee7-45a1-9053-c9159732e083",
            "f1295f36-ef46-4b89-baed-60efaa210947",
            "102fca23-9a7c-4659-95f1-692afac0fa25"
        ];

        if (safariProducts.includes(productId)) {
            return "Jeep";
        } else {
            return "Land Rover";
        }
    }

}
