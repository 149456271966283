
import { BaseView } from "@/services/BaseView";
import { DataClient } from "@/services/DataClient";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class extends BaseView {
    showLanguageSelector = true;
    allowBlog = false;

    targetElevation = 0;
    targetBackgroundColor = "transparent";

    @Prop({ required: true, type: String })
    language!: string;

    toggleSidebar() {
        this.$emit("toggleSidebar");
    }

    get isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
    }

    languages = [
        {
            title: "Español",
            code: "es",
        },
        {
            title: "English",
            code: "en",
        },
        {
            title: "Deutsch",
            code: "de",
        },
        {
            title: "Français",
            code: "fr",
        },
        {
            title: "Italiano",
            code: "it",
        },
        {
            code: "pt",
            title: "Português",
        },
        {
            code: "pl",
            title: "Polski",
        },
        {
            code: "cs",
            title: "Čeština",
        },
    ];

    async mounted() {
        // check if blog is enabled by a query parameter
        const urlParams = new URLSearchParams(window.location.search);
        this.allowBlog = urlParams.get("blog") === "true";

        if (this.fullHeight) {
            window.removeEventListener("scroll", () => this.changeColor());
            window.addEventListener("scroll", () => this.changeColor());
        }
    }

    get fullHeight(): boolean {
        return DataClient.instance.configuration.fullScreenCarrousel;
    }

    get elevation(): number {
        if (this.$route.meta?.divider) {
            return 3;
        }

        return this.fullHeight ? this.targetElevation : 3;
    }

    get backgroundColor(): string {
        return this.fullHeight ? this.targetBackgroundColor : "white";
    }

    get languageName() {
        // get language name from code
        const language = this.languages.find((item) => item.code === this.$i18n.locale);
        if (language) {
            return language.title;
        }

        // fallback to code
        return this.$i18n.locale;
    }

    get mallorca() {
        const category = DataClient.instance.getCategoryById("9b5df3c6-0747-46a3-9fce-79ea05f38cc4");
        return this.buildNavigationRoute("category", { id: category.slug });
    }

    get menorca() {
        const category = DataClient.instance.getCategoryById("73da6181-2950-4b6d-834c-d7af7da8bcd6");
        return this.buildNavigationRoute("category", { id: category.slug });
    }

    get ibiza() {
        const category = DataClient.instance.getCategoryById("f7f9588a-a756-4b7a-94bb-37fc6227c049");
        return this.buildNavigationRoute("category", { id: category.slug });
    }

    setLanguage(code: string) {
        this.$emit("languageChanged", code);
    }

    get logoClass() {
        // always without shadow on home page
        /*
        const routeStarts = ["home", "activities"];
        const shadowedRoute = routeStarts.some((route) => this.$route.name && this.$route.name.startsWith(route));

        if (shadowedRoute) {
            if (this.elevation < 3) {
                return "logo-shadow";
            }
        }
        */

        return "";
    }

    changeColor() {
        let elevation = 0;
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (scrollTop >= 0 && scrollTop < 33) {
            elevation = 0;
        } else if (scrollTop >= 33 && scrollTop < 66) {
            elevation = 1;
        } else if (scrollTop >= 66 && scrollTop < 100) {
            elevation = 2;
        } else if (scrollTop >= 100) {
            elevation = 3;
        }

        if (elevation > 0) {
            this.targetBackgroundColor = "white";
            this.targetElevation = elevation;
        } else {
            this.targetBackgroundColor = "transparent";
            this.targetElevation = 0;
        }
    }

    agentsLogin() {
        window.open("https://agents.viajescaledonia.com", "_blank");
    }
}
