import { DataClient } from "@/services/DataClient";
import VueRouter from "vue-router";

export class Sitemap {

    async generate() {
        const languages = ["es", "en", "fr", "de", "it", "pt", "pl", "cs"];
        const names = ["home", "activities", "about", "contact", "privacy-policy", "use-conditions", "meeting-points"];

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const router = (window as any).__router as VueRouter;

        const urls: string[] = [];

        for (const language of languages) {
            for (const name of names) {
                const url = router.resolve({ name: `${name}.${language}` }).href;
                urls.push(url);
            }

            const config = await DataClient.instance.getConfiguration(language);

            for (const activity of config.activities) {
                const url = router.resolve({ name: `activity.${language}`, params: { id: activity.slug } }).href;
                urls.push(url);
            }

            for (const category of config.categories) {
                const url = router.resolve({ name: `category.${language}`, params: { id: category.slug } }).href;
                urls.push(url);
            }
        }

        // generate a sitemap.xml file and force its download
        const sitemap: string[] = [];

        sitemap.push(`<?xml version="1.0" encoding="UTF-8"?>`);
        sitemap.push(`<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xhtml="http://www.w3.org/1999/xhtml">`);
        for (const url of urls) {
            sitemap.push(`    <url>`);
            sitemap.push(`        <loc>https://cityxperience.com${url}</loc>`);
            sitemap.push(`    </url>`);
        }
        sitemap.push(`</urlset>`);

        const blob = new Blob([sitemap.join("\n")], { type: "text/xml" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "sitemap.xml";
        a.click();

    }

}

(window as any)["__sitemap"] = new Sitemap();
